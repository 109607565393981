import axios from "axios";
import { axiosGenerate, GetToken } from "./axios";
import { baseUrl, v1baseUrl, urlChatMessage, getLPJK, urlLPJK, urlLinode } from "./config";
import { multiLanguage } from "../Language/getBahasa";

const getIntegrationDetail = (system_id) => {
  let currentListIntegration = JSON.parse(localStorage.getItem('integrationList'));
  let index = currentListIntegration.findIndex(x => x.system_id == system_id);
  return currentListIntegration[index];
}

export const getIntegrationByName = (system_name) => {

  let currentListIntegration = JSON.parse(localStorage.getItem('integrationList'));

  let index = currentListIntegration.findIndex(x => x.system_name == system_name);

  if(index>=0){
    return currentListIntegration[index];
  }

  return null;
}

const getIndexByValue = (array, property, value) => {
  return array.findIndex(x => x[property] == value);
}
export const GetListData = async (strUrl) => {
  let newToken = await GetToken();

  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: baseUrl + strUrl,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: "Bearer" + " " + newToken.secret_key,
      },
    };
    axios(config)
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);

          return;
        }
        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};

export const newGetListIntegration = async (system_id, url) => {
  let integrationData = await getIntegrationDetail(system_id);
  let index = await getIndexByValue(integrationData.auth_parameter, 'key', 'token');
  let config = {
    method:'get',
    url:integrationData.url + url,
    headers:{
      "Content-type": 'application/json'
    }
  }

  if(integrationData.auth_type!="Basic Auth"){

  }else{
    if(index >= 0){
      config.headers['token'] = integrationData.auth_parameter[index].value;
    }
  }

  // console.log("cek config", config)

  return new Promise((resolve, reject) => {
    axios(config).then((res) => {
      if (res.status == 200 || res.data.status == "success") {
          resolve(res.data);
          return;
      }
      reject(res.data);
    }).catch((e) => {
      reject(e.response)
    })
  })

}
export const GetListIntegration = async (strUrl) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: urlLPJK + strUrl,
      headers: {
        "Content-Type": "aplication/json",
        token: newToken.tokenIntegrate,
      },
    };
    axios(config)
      .then((res) => {
        // console.log("cek res", res.status);
        if (res.status == 200 || res.data.status == "success") {
          // console.log(res.data)
          resolve(res.data);
          return;
        }
        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};
export const GetListDataMessage = async (strUrl) => {
  let newToken = await GetToken();

  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: urlChatMessage + strUrl,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: "Bearer" + " " + newToken.secret_key,
      },
    };
    axios(config)
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);

          return;
        }
        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};

export const insertFormData = async (strUrl, body) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      url: baseUrl + strUrl,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${newToken.secret_key}`,
        "Content-Type": "multipart/form-data",
      },
      data: body,
    };
    axios(config)
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};
export const newPostIntegration = async (system_id, url, body) => {
  let integrationData = await getIntegrationDetail(system_id);
  let index = await getIndexByValue(integrationData.auth_parameter, 'key', 'token');
  let config = {
    method:'post',
    url:integrationData.url + url,
    headers:{
      "Content-type": 'application/json'
    },
    data: body
  }

  if(integrationData.auth_type!="Basic Auth"){

  }else{
    if(index >= 0){
      config.headers['token'] = integrationData.auth_parameter[index].value;
    }
  }

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        if (res.data.status === "success") {
          resolve(res.data);

          return;
        }
        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
}
export const postIntegration = async (strUrl, body) => {
  let newToken = await GetToken();

  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      url: urlLPJK + strUrl,
      headers: {
        "Content-Type": "aplication/json",
        token: newToken.tokenIntegrate,
      },
      data: body,
    };
    axios(config)
      .then((res) => {
        if (res.data.status === "success") {
          resolve(res.data);

          return;
        }
        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};

export const insertData = async (strUrl, body) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      url: baseUrl + strUrl,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${newToken.secret_key}`,
      },
      data: body,
    };
    axios(config)
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};

// export const EditData = async (strUrl, body) => {
//   let newToken = await GetToken();
//   return new Promise((resolve, reject) => {
//     axios({
//       method: "PUT",
//       url: baseUrl + strUrl,
//       headers: {

//         "Content-Type": "aplication/json",
//         Authorization: `Bearer ${newToken.secret_key}`,
//       },
//       data: body,
//     })
//       .then((res) => {
//         if (res.data.responseStatus === "SUCCESS") {
//           resolve(res.data);
//           return;
//         }

//         reject(res.data);
//       })
//       .catch((e) => {
//         reject(e.response);
//       });
//   });
// };

export const inputDataNew = async (strUrl, body) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${baseUrl}${strUrl}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${newToken.secret_key}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
    })
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};
export const inputChat = async (strUrl, body) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: urlChatMessage + strUrl,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${newToken.secret_key}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
    })
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};
export const editChat = async (strUrl, body) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: urlChatMessage + strUrl,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${newToken.secret_key}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
    })
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};
export const EditData = async (strUrl, body) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${baseUrl}${strUrl}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${newToken.secret_key}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
    })
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};

export const DeleteData = async (strUrl) => {
  let newToken = await GetToken();
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url: baseUrl + strUrl,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${newToken.secret_key}`,
      },
    })
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};
export const DeleteChat = async (strUrl, body) => {
  let newToken = await GetToken();

  return new Promise((resolve, reject) => {
    axios({
      data: JSON.stringify(body),
      method: "DELETE",
      url: urlChatMessage + strUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${newToken.secret_key}`,
      },
    })
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          resolve(res.data);
          return;
        }

        reject(res.data);
      })
      .catch((e) => {
        reject(e.response);
      });
  });
};

export const refreshToken = () => {
  const setLocalStorage = (responseJSON) => {
    localStorage.setItem("secret_key", responseJSON.secret_key);
  };
  const data = {};
  const token = localStorage.getItem("secret_key");
  data["token"] = token;

  insertData("/users/refresh_token", data)
    .then((response) => {
      let responseJSON = response;
      if (response.responseStatus === "SUCCESS") {
        setLocalStorage(responseJSON);
        window.location.reload();
      }
    })
    .catch((error) => {
      console.log("error refreshToken");
    });
};

export const formatRupiah = (angka) => {
  var number_string = angka.replace(/[^,\d]/g, '').toString(),
  split   		= number_string.split(','),
  sisa     		= split[0].length % 3,
  rupiah     		= split[0].substr(0, sisa),
  ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if(ribuan){
    let separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
  return 'Rp '+rupiah;
}

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export const handleUrlFile = (url = '') => {
  let newBaseUrl = deleteSlashBase(urlLinode);
  let newPathFile = '';
  
  if(url){
    newPathFile = deleteSlashPath(url);
  }
  
  
  return newBaseUrl+'/'+newPathFile;
}

const deleteSlashBase = (valueUrl) => {
  let newBaseUrl = valueUrl;
  let slash = valueUrl.endsWith("/");

  if(slash){
    newBaseUrl = removeCharAt(newBaseUrl)
  }

  return newBaseUrl
}

const deleteSlashPath = (valuePath) => {
  let newBaseUrl = valuePath;

  let slash = '';

  try {
    slash = valuePath.startsWith("/");
  } catch (err) {
    slash = "";
  }
  

  if(slash){
    newBaseUrl = removeCharAt(newBaseUrl, 'first');
  }

  return newBaseUrl
}

export const deleteSlashTwo = (urlLeft, urlRight) => {
  let newLeft = "";
  let newRight = "";

  if(urlLeft){
    newLeft = deleteSlashBase(urlLeft);
  }

  if(urlRight){
    newRight = deleteSlashPath(urlRight);
  }

  return newLeft+"/"+newRight;
}

const removeCharAt = (val, pos=null) => {
  var tmp = val.split(''); // convert to an array
  if(pos==='first'){
    tmp.shift();
  }else{
    tmp.pop();
  }
  return tmp.join(''); // reconstruct the string
}