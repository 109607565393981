import axios from "axios";
import { Modal } from "antd";
import { baseUrl } from "../components/config/config";
import { refreshToken } from "../components/config/actions";

const customAxios = axios.create({
  baseURL: `${baseUrl}v2/`,
  headers:{
    "Content-Type": "application/json",
    "Accept": "application/json",
    Authorization: "Bearer "+localStorage.getItem("secret_key"),
  },
});

const trialError = () => {
  Modal.error({
    title: "Pesan Error",
    content:
      "Masa trial anda telah habis,Harap menghubungi Admin NAS untuk info lebih lanjut",
    onOk() {
      localStorage.clear();
      window.location.replace("/login");
    },
  });
}

customAxios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error){

  if(error.status === 401){
    refreshToken();
    return false;
  }

  if(error.status === 419){
    trialError();
    return false;
  }

  return Promise.reject(error);
});


export default customAxios;