import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Alert,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Select, Spin, Divider, Modal as Modal1 } from "antd";
import { Brand_LSP, path_jobs } from "../../components/config/config";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import "moment/locale/id";
import "../../css/loaderComponent.css";
import "antd/dist/antd.css";
import LoadingOverlay from "react-loading-overlay";
import { multiLanguage } from "../../components/Language/getBahasa";
import LabelRequired from "../../components/Label/LabelRequired";
import { baseUrl, path_religion } from "../../components/config/config";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { UploadPicture } from "../../components/Button/ButtonUploadPicture";
import { insertData } from "../../components/config/actions";
import FormSelectWilayah from "../../components/Hoc/FormSelectWilayah";

const { Option } = Select;
class RegistrasiAsesor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      symbolsArr: ["e", "E", "+", "-", "."],
      hidden: true,
      payloadReligion: [],
      payloadJobs: [],
      fetching: false,
      uploadTTD: [],
      signature: "",
      username: "",
      modalSignature: false,
      payloadLsp: [],
      payloadArea: [],
      lsp: "",
    };
  }

  componentDidMount() {
    axios(`${baseUrl}/public${path_religion}?limit=100`).then((response) => {
      this.setState({ payloadReligion: response.data.data });
    });
    axios(`${baseUrl}/public/${path_jobs}?limit=100`).then((response) => {
      this.setState({ payloadJobs: response.data.data });
    });
    axios(`${baseUrl}/public/lsp?limit=unlimited`).then((response) => {
      this.setState({ payloadLsp: response.data.data });
    });
  }

  onChangeKelurahan = (value) => {
    this.setState({
      kelurahan_id: value.key,
    });
  };

  handleUsernameEmail = (input) => (event) => {
    if (input === "username") {
      this.setState({ [input]: event.target.value });
      this.Get_Username(event.target.value);
    } else {
      this.setState({ [input]: event.target.value });
      this.Get_Emails(event.target.value);
    }
  };
  onSearch = (value) => {
    this.setState({
      fetching: true,
    });
    const path = `${baseUrl}/public` + path_jobs;
    axios(path).then((response) => {
      this.setState({
        payloadJobs: response.data.data,
        fetching: false,
      });
    });
  };
  handleBack = () => {
    Modal1.confirm({
      content: "Apakah anda yakin akan ke halaman login ?",
      onOk() {
        localStorage.clear();
        window.location.replace("/login");
      },
    });
  };
  onLspSearch = () => {
    this.setState({
      fetching: true,
    });
    const path = `${baseUrl}/public/lsp`;
    axios(path).then((response) => {
      this.setState({
        payloadLsp: response.data.data,
        fetching: false,
      });
    });
  };

  onChangeKelurahan = (value) => {
    this.setState({
      kelurahan_id: value.key,
    });
  };

  handleChange = (event, errors, values) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors,
      values,
    });
  };
  onChangeReligion = (input) => (value) => {
    this.setState({
      religion: value,
    });
  };
  onChangeJobs = (input) => (value) => {
    this.setState({
      jobs: value,
    });
  };
  onChangeLsp = (input) => (value) => {
    this.setState({
      lsp: value,
    });
  };
  Get_Username = (value) => {
    this.setState({
      loading: true,
    });
    const path = `/public/users_validation?username=${value}`;
    axios(baseUrl + path)
      .then((response) => {
        this.setState({
          loading: false,
          hidden: true,
          message: "",
        });
      })
      .catch((error) => {
        this.setState({
          response: error.response.data.error.code,
        });

        switch (this.state.response) {
          case 400:
            this.setState({
              hidden: false,
              message: "Username sudah terdaftar",
              loading: false,
            });
            break;

          default:
            break;
        }
      });
  };
  Get_Emails = (value) => {
    this.setState({
      loading: true,
    });
    const path = `/public/users_validation?email=${value}`;
    axios(baseUrl + path)
      .then((response) => {
        this.setState({
          loading: false,
          hidden: true,
          message: "",
        });
      })
      .catch((error) => {
        this.setState({
          response: error.response.data.error.code,
        });

        switch (this.state.response) {
          case 400:
            this.setState({
              hidden: false,
              message: "Email sudah terdaftar",
              loading: false,
            });
            break;

          default:
            break;
        }
      });
  };
  toggleSignature = () => {
    this.setState({
      modalSignature: !this.state.modalSignature,
    });
  };

  changeSignature = (event) => {
    console.log(
      "ChangeSignature",
      this.sigPad.getTrimmedCanvas().toDataURL("image/png")
    );
    this.setState({
      signature: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
      modalSignature: !this.state.modalSignature,
    });
  };
  uploadProps = (upload) => {
    this.setState({
      signature: upload.base64,
      modalSignature: !this.state.modalSignature,
    });
  };

  clear = () => {
    this.sigPad.clear();
  };

  handleValidation = () => {
    const emailReg = /(.+)@(.+){2,}\.(.+){2,}/;
    const usernameReg = /^\S*$/;
    if (
      this.state.contact == "" ||
      this.state.first_name == "" ||
      this.state.gender_code == "" ||
      this.state.place_of_birth == "" ||
      this.state.date_of_birth == "" ||
      this.state.religion == "" ||
      this.state.registration_number == "" ||
      this.state.lsp.length < 1 ||
      this.state.jobs == "" ||
      this.state.pendidikan_terakhir == "" ||
      this.state.address == "" ||
      this.state.signature == ""
    ) {
      this.setState({
        hidden: false,

        message: multiLanguage.alertInput,
        loading: false,
      });
    } else if (!usernameReg.test(this.state.username)) {
      this.setState({
        hidden: false,
        message: "Masih ada spasi dalam kolom username",
      });
    } else if (this.state.kelurahan_id === "") {
      this.setState({
        loading: false,
        hidden: false,
        message: "Data Wilayah harap diisi",
      });
    } else if (!emailReg.test(this.state.email)) {
      this.setState({
        hidden: false,
        message: "Format Email tidak sesuai",
      });
    } else if (
      this.state.nik == "" ||
      this.state.nik.length < 16 ||
      this.state.nik.length > 16
    ) {
      this.setState({
        hidden: false,
        message: "Nik harus 16 digit",
        loading: false,
      });
    } else {
      this.handleSubmit();
    }
  };
  handleSubmit = (event) => {
    this.setState({ loading: true });

    this.setState({
      loading: true,
    });

    insertData("/public/users/accessors", {
      username: this.state.username,
      email: this.state.email,
      contact: this.state.contact,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      gender_code: this.state.gender_code,
      place_of_birth: this.state.place_of_birth,
      date_of_birth: this.state.date_of_birth,
      religion: this.state.religion.label,
      registration_number: this.state.registration_number,
      ref_id: this.state.lsp.key,
      nik: this.state.nik,
      role_code: "ACS",
      pendidikan_terakhir: this.state.pendidikan_terakhir,
      address: this.state.address,
      signature: this.state.signature,
      jobs_code: this.state.jobs.key,
      kelurahan_id: this.state.kelurahan_id,
    })
      .then((res) => {
        localStorage.setItem("email", this.state.email);
        this.setState({
          loading: false,
        });

        window.location.replace("/confirm");

        return;
      })
      .catch((error) => {
        // console.log(error);
        this.setState({
          loading: false,
        });
        this.setState({
          response: error.data.error.code,
        });
        switch (this.state.response) {
          case 400:
            this.setState({
              hidden: false,
              message: error.data.error.message,
              loading: false,
            });
            break;

          case 409:
            this.setState({
              hidden: false,
              message: multiLanguage.alertAlready,
              loading: false,
            });
            break;

          default:
            break;
        }
      });
  };

  render() {
    const {
      fetching,
      payloadJobs,
      payloadReligion,
      modalSignature,
      payloadLsp,
      payloadArea,
    } = this.state;
    const { Logo } = Brand_LSP("demo");
    const now = new Date(new Date().setFullYear(new Date().getFullYear() - 17))
      .toJSON()
      .split("T")[0];
    let sevenYearsAgo = new Date();
    sevenYearsAgo.setFullYear(sevenYearsAgo.getFullYear() - 200);
    sevenYearsAgo = sevenYearsAgo.toJSON().split("T")[0];
    const reg = /^\d+$/;
    return (
      <div>
        <LoadingOverlay active={this.state.loading} spinner text="Loading..">
          <Modal
            isOpen={modalSignature}
            toggle={this.toggleSignature}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleSignature}>
              {multiLanguage.change} {multiLanguage.signature}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <span>{multiLanguage.alertSignature}</span>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="9">
                  <div
                    style={{
                      backgroundColor: "gray",
                      width: 400,
                      height: 400,
                      marginBottom: "5%",
                    }}
                  >
                    <SignatureCanvas
                      ref={(ref) => {
                        this.sigPad = ref;
                      }}
                      penColor="black"
                      canvasProps={{
                        width: 400,
                        height: 400,
                        className: "sigCanvas",
                      }}
                    />
                  </div>
                  {this.sigPad === {} ? (
                    ""
                  ) : (
                    <div>
                      <Button
                        color="warning"
                        onClick={this.clear}
                        style={{ marginRight: "50px" }}
                      >
                        <i className="fa fa-eraser" /> {multiLanguage.clear}
                      </Button>

                      <UploadPicture upload={this.uploadProps} />
                    </div>
                  )}
                </Col>
              </Row>
              <p />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={this.toggleSignature}>
                {multiLanguage.cancel}
              </Button>
              <Button
                type="submit"
                color="success"
                onClick={this.changeSignature}
              >
                <i className="fa fa-check" /> {multiLanguage.submit}
              </Button>
            </ModalFooter>
          </Modal>
          <AvForm
            action=""
            encType="multipart/form-data"
            className="form-horizontal"
          >
            <AvGroup row>
              <Col md="12">
                <img
                  src={Logo}
                  style={{
                    width: 200,
                    float: "right",
                    marginRight: "100px",
                    marginTop: "15px",
                  }}
                />
              </Col>
            </AvGroup>
            <AvGroup row>
              <Col md="3" style={{ marginLeft: 145, marginTop: "50px" }}>
                <LabelRequired fors="username" label={"Nama Pengguna"} />
                <AvInput
                  type="text"
                  id="username"
                  name="username"
                  placeholder={"Username"}
                  onChange={this.handleUsernameEmail("username")}
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
              <Col md="3" style={{ marginTop: "50px" }}>
                <LabelRequired fors="email" label={"Email"} />
                <AvInput
                  type="text"
                  id="email"
                  name="email"
                  placeholder={"Email"}
                  onChange={this.handleUsernameEmail("email")}
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
              <Col md="3" style={{ marginTop: "50px" }}>
                <LabelRequired fors="contact" label={"Nomor Telepon"} />

                <AvField
                  type="text"
                  id="contact"
                  name="contact"
                  maxLength="14"
                  onChange={this.handleChange}
                  placeholder={"Nomor Telepon"}
                  onKeyPress={(event) => {
                    if (!/^[0-9 +]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  defaultValue={this.state.contact}
                  onKeyDown={(e) =>
                    this.state.symbolsArr.includes(e.key) && e.preventDefault()
                  }
                  pattern="^[0-9]*$"
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
            </AvGroup>
            <AvGroup row>
              <Col md="3" style={{ marginLeft: 145 }}>
                <LabelRequired fors="first_name" label={"Nama Depan"} />
                <AvInput
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder={"Nama Depan"}
                  onChange={this.handleChange}
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
              <Col md="3">
                <Label style={{ marginTop: 5 }}>Nama Belakang</Label>
                <AvInput
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder={"Nama Belakang"}
                  onChange={this.handleChange}
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
              <Col md="3">
                <LabelRequired
                  fors="gender_code"
                  label={multiLanguage.gender}
                />

                <AvRadioGroup
                  inline
                  name="gender_code"
                  required
                  style={{ marginLeft: 20 }}
                  errorMessage={multiLanguage.alertRadioButton}
                >
                  <AvRadio
                    label={multiLanguage.male}
                    value="M"
                    onChange={this.handleChange}
                  />
                  <AvRadio
                    label={multiLanguage.female}
                    value="F"
                    onChange={this.handleChange}
                  />
                </AvRadioGroup>
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
            </AvGroup>
            <AvGroup row>
              <Col md="3" style={{ marginLeft: 145 }}>
                <LabelRequired fors="place_of_birth" label={"Tempat Lahir"} />

                <AvInput
                  type="text"
                  id="plcae_of_birth"
                  name="place_of_birth"
                  placeholder={"Tempat Lahir"}
                  onChange={this.handleChange}
                  // required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
              <Col md="3">
                <LabelRequired fors="date_of_birth" label={"Tanggal Lahir"} />

                <AvInput
                  type="date"
                  id="date_of_birth"
                  name="date_of_birth"
                  min={sevenYearsAgo}
                  max={now}
                  placeholder={"Tanggal Lahir"}
                  onChange={this.handleChange}
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>

              <Col md="3">
                <LabelRequired fors="nik" label={"NIK"} />
                <AvField
                  type="text"
                  id="nik"
                  name="nik"
                  maxLength="16"
                  pattern="[0-9]*"
                  onChange={this.handleChange}
                  value={this.state.nik}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={(e) =>
                    this.state.symbolsArr.includes(e.key) && e.preventDefault()
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: multiLanguage.alertName,
                    },
                    maxLength: {
                      value: 16,
                      errorMessage: "Nik anda melebihi 16 digit",
                    },
                  }}
                />
              </Col>
            </AvGroup>
            <AvGroup row>
              <Col md="3" style={{ marginLeft: 145 }}>
                <LabelRequired
                  fors="registration_number"
                  label={"Nomor Registrasi Asesor"}
                />

                <AvInput
                  type="text"
                  id="registration_number"
                  name="registration_number"
                  placeholder={"Nomor Registrasi Asesor"}
                  onChange={this.handleChange}
                  required
                />
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>

              <Col md="3">
                <LabelRequired fors="religion" label={multiLanguage.religion} />

                <Select
                  showSearch
                  labelInValue
                  placeholder={
                    <Label style={{ color: "#5c6873" }}>
                      {multiLanguage.select + " " + multiLanguage.religion}
                    </Label>
                  }
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  onChange={this.onChangeReligion("religion")}
                  style={{ width: "100%" }}
                >
                  {payloadReligion.map((d) => (
                    <Option key={d.row_id}>{d.religion}</Option>
                  ))}
                </Select>
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>
              <Col md="3">
                <LabelRequired fors="jobs_code" label={multiLanguage.jobs} />

                <Select
                  showSearch
                  labelInValue
                  placeholder={
                    <Label style={{ color: "#5c6873" }}>
                      {multiLanguage.select + " " + multiLanguage.jobs}
                    </Label>
                  }
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                  onSearch={this.onSearch}
                  onChange={this.onChangeJobs("jobs")}
                  style={{ width: "100%" }}
                >
                  {payloadJobs.map((d) => (
                    <Option key={d.jobs_code}>{d.jobs_name}</Option>
                  ))}
                </Select>
              </Col>
            </AvGroup>
            <AvGroup row>
              <Col md="9" style={{ marginLeft: 145 }}>
                <LabelRequired
                  fors="kelurahan_id"
                  label={"Alamat Wilayah (Ketik nama wilayah untuk pencarian)"}
                />
                <FormSelectWilayah onChange={this.onChangeKelurahan} />
              </Col>
            </AvGroup>

            <Divider orientation="left" style={{ color: "#c8ced3" }} />
            <AvGroup row>
              <Col md="3" style={{ marginLeft: 145 }}>
                <LabelRequired
                  fors="pendidikan_terakhir"
                  label={multiLanguage.lastEducation}
                />

                <AvField
                  type="select"
                  id="pendidikan_terakhir"
                  name="pendidikan_terakhir"
                  onChange={this.handleChange}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: multiLanguage.alertName,
                    },
                  }}
                >
                  <option value="">{multiLanguage.select}</option>
                  <option value="SD">SD</option>
                  <option value="SMP">SMP</option>
                  <option value="SMA/Sederajat">SMA/Sederajat</option>
                  <option value="D1">D1</option>
                  <option value="D2">D2</option>
                  <option value="D3">D3</option>
                  <option value="D4">D4</option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                  <option value="S3">S3</option>
                </AvField>
                <AvFeedback>{multiLanguage.alertField}</AvFeedback>
              </Col>

              <Col md="3">
                <LabelRequired
                  fors="signature"
                  label={multiLanguage.signature}
                />

                {this.state.signature === "" ? (
                  <Button onClick={this.toggleSignature} className="btn-cancel">
                    {multiLanguage.signature}
                  </Button>
                ) : this.state.uploadTTD != "" ? (
                  <div>
                    <img
                      style={{
                        width: 260,
                        height: 110,
                        backgroundColor: "grey",
                      }}
                      src={this.state.uploadTTD.base64}
                    />
                    <h6> {this.state.uploadTTD.filename}</h6>

                    <h6>
                      <Button color="link" onClick={this.toggleSignature}>
                        Ganti ?
                      </Button>
                    </h6>
                  </div>
                ) : this.state.signature != "" ? (
                  <div>
                    <img
                      style={{
                        width: 260,
                        height: 110,
                        backgroundColor: "grey",
                      }}
                      src={this.state.signature}
                    />

                    <h6>
                      <Button color="link" onClick={this.toggleSignature}>
                        Ganti ?
                      </Button>
                    </h6>
                  </div>
                ) : null}
              </Col>
              <Col md="3">
                <LabelRequired fors="LSP" label={"LSP"} />
                <Select
                  showSearch
                  labelInValue
                  placeholder={
                    <Label style={{ color: "#5c6873" }}>
                      {multiLanguage.select + " " + "Lsp"}
                    </Label>
                  }
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                  onSearch={this.onLspSearch}
                  onChange={this.onChangeLsp("lsp")}
                  style={{ width: "100%" }}
                >
                  {payloadLsp.map((d) => (
                    <Option key={d.lsp_id}>{d.lsp_name}</Option>
                  ))}
                </Select>
              </Col>
            </AvGroup>
            <Alert
              color="danger"
              style={{ marginTop: "2px" }}
              hidden={this.state.hidden}
              className="text-center"
            >
              {this.state.message}
            </Alert>
          </AvForm>

          <p />
          <Row className="justify-content-end">
            <Col md="1.5">
              <Button
                type="submit"
                size="md"
                className="btn-primary-sm"
                onClick={this.handleBack}
              >
                <i className="fa fa-chevron-left"> </i>
                {"Login Page"}
              </Button>
            </Col>
            <Col md="1.5">
              <Button
                className="btn-submit-primary"
                type="submit"
                size="md"
                color="primary"
                style={{ marginLeft: "20px", marginRight: "180px" }}
                onClick={this.handleValidation}
              >
                <i className="fa fa-check" /> {"Register"}
              </Button>
            </Col>
          </Row>
        </LoadingOverlay>
      </div>
    );
  }
}

export default RegistrasiAsesor;
