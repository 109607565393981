import React, { Component, Suspense } from "react";
import { PostData } from "../services/PostData";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Alert,
  Form,
  Row,
  // Modal,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
} from "reactstrap";
import { Input, Modal } from "antd";
import { withAlert } from "react-alert";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import firebase from "firebase/app";
import "firebase/messaging";

// import "../css/All.css";
// import "../css/LSP_Demo.css";

// import "../css/Button.css";
import {
  baseUrl,
  path_forgotPass,
  path_refreshToken,
  Brand_LSP,
} from "../components/config/config";
import { Digest } from "./Helpers/digest";

import "antd/dist/antd.css";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingLogo: true,
      username_email: "",
      password: "",
      logged_in: false,
      message: "",
      hidden: true,
      modal: false,
      nestedModal: false,
      closeAll: false,
      response: "",
      register_id: "",
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleNested = (event) => {
    event.preventDefault();

    var data = new FormData();
    data.append("email", this.state.email);
    const options = {
      method: "POST",
      url: baseUrl + path_forgotPass,
      data: data,
    };
    axios(options).then((response) => {
      if (response.data.responseStatus == "SUCCESS") {
        Modal.success({
          title: "SUCCESS",
          content:
            "Tautan untuk mengganti password berhasil dikirim ke e-mail, silahkan cek kembali email Anda",
          onOk() {
            localStorage.clear();
            window.location.replace("/login");
          },
        });
      }
    });
  };

  render() {
    if (localStorage.getItem("logged_in") || this.state.logged_in) {
      return <Redirect to={"/"} />;
    }
    const { Logo } = Brand_LSP("login");

    return (
      <LoadingOverlay active={this.state.loading} spinner text="Please Wait...">
        <div className="app flex-row align-items-center animated fadeIn backgroundColor">
          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <Card
                  className="p-4"
                  style={{ width: "52%", margin: "auto", height: "auto" }}
                >
                  <CardBody>
                    <Form onSubmit={this.login}>
                      <Row className="logo">
                        <Col>
                          <img className="login" src={Logo} alt="" />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          style={{
                            fontSize: "50%",
                            marginTop: "1px",
                            marginBottom: "10px",
                          }}
                          className="forgot float-right"
                        >
                          <h5>Forgot Your Password ?</h5>
                          <h6>
                            We will sent your email to reset your current
                            password
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email/username"
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "10px" }}>
                        <Col>
                          <Button
                            className="btn-login"
                            onClick={this.toggleNested}
                          >
                            SEND REQUEST
                          </Button>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <Col>
                          <Link to={"/login"}>
                            <Button className="btn-forgot-back">BACK</Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>

                    <Row className="center-block">
                      <Alert
                        color="danger"
                        hidden={this.state.hidden}
                        style={{
                          marginTop: "3%",
                          fontSize: "72%",
                          marginBottom: "-7%",
                        }}
                      >
                        {this.state.message}
                      </Alert>
                    </Row>

                    <Row
                      className="footerCopyright"
                      style={{ marginTop: "70px" }}
                    >
                      <Col>
                        Copyright &copy; 2019,
                        <a href="https://www.aplikasisertifikasi.com">NAS</a>.
                        All rights reserved
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {/* </CardGroup> */}
              </Col>
            </Row>
            <NotificationContainer />
          </Container>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withAlert(ForgotPassword);
