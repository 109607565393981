import customAxios from "./configAxios";

const urlHere = 'public/area';

// params
// ?limit=100

const getArea = async (params) => {
  const result = await customAxios.get(`${urlHere}${params ? params : ''}`).then((response) => response).catch((err) => err);

  if(result.status === 200){
    const data = result.data;
    data['status'] = result.status;
    return result.data;
  }
  
  return result;
}

export {
  getArea
}