import React, * as react from "react";
import { Label } from "reactstrap";
import { Select, Spin } from "antd";
import axios from "axios";
import { baseUrl } from "../config/config";
import { multiLanguage } from "../Language/getBahasa";
import { getArea } from "../../repository/area";

const { Option } = Select;
export default class FormSelectWilayah extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      payloadArea: [],
    };
  }
  handleChange = (value) => {
    this.props.onChange(value);
  };
  
  componentDidMount() {
    // axios(`${baseUrl}/public/area?search=${this.props.province_name}`).then((response) => {
    //   this.setState({ payloadArea: response.data.data });
    // });
    this.getDataArea();
  }

  getDataArea = async() => {
    const result = await getArea(`?search=${this.props.kelurahan_name ? this.props.kelurahan_name : ''}`);
    if(result.responseStatus === 'success'){
      this.setState({ payloadArea: result.data });
    }
    // axios(`${baseUrl}/public/area?search=${this.props.kelurahan_name}`).then((response) => {
    //   this.setState({ payloadArea: response.data.data });
    // });
  }


  componentDidUpdate(prevProps, prevState){
    if(prevProps.kelurahan_name!==this.props.kelurahan_name){
      this.getDataArea();
    }
  }
  onKelurahanSearch = async (value) => {
    this.setState({
      fetching: true,
    });

    if (value.length > 3) {
      const result = await getArea(`?search=${value}`);
      if(result.responseStatus === 'success'){
        this.setState({ payloadArea: result.data, fetching: false });
      }
    } else if (value === "") {
      const result = await getArea('?limit=100');
      if(result.responseStatus === 'success'){
        this.setState({ payloadArea: result.data, fetching: false });
      }
    }


    // if (value.length > 3) {
    //   const path = `${baseUrl}public/area?search=${value}`;
    //   axios(path).then((response) => {
    //     this.setState({
    //       payloadArea: response.data.data,
    //       fetching: false,
    //     });
    //   });
    // } else if (value === "") {
    //   const path = `${baseUrl}public/area`;
    //   axios(path).then((response) => {
    //     this.setState({
    //       payloadArea: response.data.data,
    //       fetching: false,
    //     });
    //   });
    // }
  };

  render() {
    const { payloadArea, fetching } = this.state;
    const {
      province_name,
      kota_name,
      kecamatan_name,
      kelurahan_name,
      kelurahan_id,
    } = this.props;

    // const dataConcat =
    //   province_name === undefined
    //     ? ""
    //     : province_name.concat(
    //         " - ",
    //         kota_name,
    //         " - ",
    //         kecamatan_name,
    //         " - ",
    //         kelurahan_name
    //       );
    return (
      <>
        {this.props.province_name == undefined ? (
          <Select
            showSearch
            placeholder={
              <Label style={{ color: "#5c6873" }}>
                {multiLanguage.select + " Wilayah"}
              </Label>
            }
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={this.onKelurahanSearch}
            onChange={this.handleChange}
            style={{ width: "100%" }}
          >
            {payloadArea.map((d) => (
              <Option key={d.kelurahan_id}>
                {d.province_name} - {d.kota_name} - {d.kecamatan_name} -{" "}
                {d.kelurahan_name}
              </Option>
            ))}
          </Select>
        ) : (
          <Select
            value={kelurahan_id}
            showSearch
            placeholder={
              <Label style={{ color: "#5c6873" }}>
                {multiLanguage.select + " Wilayah"}
              </Label>
            }
            notFoundContent={fetching ? <Spin size="small" /> : null}
            onChange={this.handleChange}
            onSearch={this.onKelurahanSearch}
            filterOption={false}
            style={{ width: "100%" }}
          >
            {payloadArea.map((d) => (
              <Option key={d.kelurahan_id.toString()} value={d.kelurahan_id.toString()}>
                {d.province_name} - {d.kota_name} - {d.kecamatan_name} -{" "}
                {d.kelurahan_name}
              </Option>
            ))}
          </Select>
        )}
      </>
    );
  }
}
